import {Link, navigate} from 'gatsby'
import React, {useEffect} from 'react'

import BackSvg from '../images/svgs/back.svg'
import {MDXProvider} from '@mdx-js/react'
import {MDXRenderer} from 'gatsby-plugin-mdx'
import {graphql} from 'gatsby'
import {useLayoutValue} from '../components/ui/layoutProvider'

const shortcodes = {Link} // Provide common components here
export default function PageTemplate({data: {mdx}}) {
  const {state, dispatch} = useLayoutValue()
  useEffect(() => {
    dispatch({
      type: 'SET_MENU',
      value: true,
    })
  }, [])
  return (
    <div className="  flex flex-col bg-main  w-full mb-12 font-sans">
      <div className=" flex md:flex-row text-white  justify-between w-full  bg-gray tracking-widest">
        <div className="bg-gray  pt-20 mx-6 text-5xl md:text-7xl pt-20 leading-header">
          {mdx.frontmatter.title}
        </div>
        <button
          className="m-2 mx-4 self-end"
          onClick={() => {
            navigate(-1)
          }}>
          <BackSvg className="h-8" />
        </button>
      </div>
      <div className="flex flex-col w-full p-6 pt-12">
        {/* <div className="md:text-6xl">{mdx.frontmatter.title}</div> */}
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </div>
    </div>
  )
}
export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: {eq: $id}) {
      id
      body
      frontmatter {
        title
      }
      excerpt
    }
  }
`
